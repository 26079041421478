<template>
  <div class="menu-wrap">
    <el-menu :defaultActive="navIndex" @select="handleSelect" mode="horizontal" class="el-menu-vertical-demo" active-text-color="#49C265">
      <el-menu-item :index="index+''" :key="m.id" v-for="(m, index) in list">{{m.title}}</el-menu-item>
    </el-menu>
    <div class="content" v-if="list[navIndex]" v-html="list[navIndex].content"></div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      navIndex: "0",
      list: [],
    };
  },
  created() {
    // 在组件实例处理完所有与状态相关的选项后调用。
    if (!this.s_getUser) {
      this._home();
    }
    this.getHelp();
  },
  mounted() {
    // 在组件被挂载之后调用。
  },
  methods: {
    // ...mapMutations([])
    async getHelp() {
      let { code, data } = await this.$y_ajax("api/help/question", { cid: 10});
      if (code == 200) {
        this.list = data;
      }
    },
    handleSelect(i) {
      this.navIndex = i;
    },
  },
  computed: {
    // 计算属性
    ...mapGetters(["s_getUser"]),
  },
  watch: {
    s_getUser(n) {
      if (!n) {
        this._home();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.menu-wrap {
  display: block;

  .content {
    min-height: 100vh;
    background: #fff;
    padding: 10px;
  }
}
</style>